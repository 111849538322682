import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import {
  CreateHappeningForm,
  createHappeningFormSchema,
  FormItem,
  HappeningPartner,
  HappeningsListPartner
} from 'api/models'
import { Button, Grid, Typography, Box, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { FormCard } from 'app/components/form/form-card.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import { FormInputProps } from 'app/components/form/controlled-form.component'

export const HappeningAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { createHappening, getHappeningsPartners } = useFetcher()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [partners, setPartners] = useState({} as HappeningsListPartner)
  const { handleMutation } = useFeedback()

  const methods = useForm<CreateHappeningForm>({
    resolver: zodResolver(createHappeningFormSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      place: '',
      description: '',
      publicatedAt: dayjs().utc(true),
      nbUsers: 0,
      maxUsers: null,
      banner: '',
      isActive: true,
      partnerLink: '',
      begin: dayjs().utc().add(1, 'day').hour(9).minute(0),
      end: dayjs().utc().add(1, 'day').hour(18).minute(0)
    }
  })
  const { isSubmitting, isValid } = methods.formState

  const initPartners = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getHappeningsPartners,
      onSuccess: (partnersData) => {
        setPartners(partnersData)
      },
      onEnd: () => setIsLoading(false)
    })
  }, [handleMutation, methods])

  const handleSubmit = async (data: CreateHappeningForm) => {
    await handleMutation({
      confirm: {
        content: t('confirm_create_happening')
      },
      onStart: () => setIsLoading(true),
      data: data,
      mutation: createHappening,
      onSuccess: () => navigate(`/happenings`),
      toastSuccess: t('create_happening_success'),
      toastError: t('create_happening_error'),
      onEnd: () => setIsLoading(false)
    })
  }

  useEffect(() => {
    initPartners().then()
  }, [])
  const organizedByPostValue = methods.watch('organizedByPost')

  const formItems = useMemo<FormInputProps[]>(() => {
    return [
      { type: 'textfield', label: t('name'), name: 'name', required: true },
      {
        type: 'centers',
        label: `${t('centers')} *`,
        name: 'centers',
        required: true,
        inputProps: {
          allCenters: true,
          multiple: true
        }
      },
      {
        type: 'select',
        label: t('organized_by'),
        name: 'organizedByPost',
        required: true,
        formItem: {
          values: partners.items
            ? [
                { id: '0', label: 'Aucun' },
                ...partners.items.map((partner: HappeningPartner) => ({
                  id: partner.id,
                  label: partner.name
                }))
              ]
            : []
        } as FormItem
      },
      ...(organizedByPostValue && organizedByPostValue !== '0'
        ? [
            {
              type: 'textfield',
              label: t('link_partners') + ' *',
              name: 'partnerLink'
            } as FormInputProps
          ]
        : []),
      { type: 'textfield', label: t('description'), name: 'description' },
      { type: 'textfield', label: t('place'), name: 'place', required: true },
      {
        type: 'datetimepicker',
        name: 'begin',
        label: `${t('begin_at')} *`,
        required: true
      },
      {
        type: 'datetimepicker',
        name: 'end',
        label: `${t('end_at')} *`,
        required: true
      },
      { type: 'datetimepicker', label: t('publicated_at'), name: 'publicatedAt' },
      {
        type: 'number',
        label: t('number_of_participants'),
        name: 'nbUsers',
        inputProps: { step: 1 }
      },
      {
        type: 'number',
        label: t('number_max_of_participants'),
        name: 'maxUsers',
        inputProps: { step: 1 }
      },
      { type: 'textfield', label: t('banner'), name: 'banner' },
      { type: 'checkbox', label: t('is_active'), name: 'isActive' }
    ]
  }, [t, organizedByPostValue, partners.items])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('add_happening')}
        </Typography>
      </Box>
      <Grid
        container
        rowSpacing={8}
        columnSpacing={{ xs: 2, sm: 4, md: 8 }}
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid item xs={12} md={12}>
          <FormCard
            isLoading={isLoading}
            title={t('general_informations')}
            control={methods.control}
            items={formItems}
          />
        </Grid>
      </Grid>
      <Grid sx={{ marginTop: 4, margin: 2 }} container columns={12} columnSpacing={4}>
        <Grid item xs={12} textAlign={'center'}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => navigate('/happenings')}
            sx={{ marginRight: 2 }}
          >
            {t('cancel')}
          </Button>
          <Button
            disabled={isSubmitting || !isValid}
            variant={'contained'}
            color={'primary'}
            onClick={methods.control.handleSubmit(handleSubmit)}
          >
            {t('save')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
