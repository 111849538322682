import React, { useCallback, useState } from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { HappeningPartner } from 'api/models'
import { SearchInput } from 'app/components/filters/search-input'
import { AddCircle } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { FiltersContainer } from 'app/components/filters/filters-container'

export const HappeningListPartnersView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getRight } = useApp()
  const { getHappeningsPartners, setSearchParams, searchParams } = useFetcher()
  const [partners, setPartners] = useState<HappeningPartner[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      if (reset) setListIsLoading(true)
      try {
        const response = getHappeningsPartners.data
        if (!response) return
        const partners = response.items as HappeningPartner[]
        setPartners((prevState) => (reset ? partners : [...prevState, ...partners]))
        setTotal(response.total)
        setIsLast(response.last)
        setOffset(response.actual)
      } finally {
        setListIsLoading(false)
      }
    },
    [getHappeningsPartners, setIsLast, setOffset, setTotal]
  )

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('partners')}
          {getRight('happening', 'isAdd') && (
            <IconButton
              title={t('add_partner')}
              aria-label={t('add_partner')}
              color="primary"
              href={'/partner/add'}
              style={{ marginLeft: 7 }}
            >
              <AddCircle fontSize="small" />
            </IconButton>
          )}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          onChange={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchInput
            slug={'name'}
            label={'name'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersContainer>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {partners.length} / {total}
          </Typography>
        </Grid>
        <List
          items={partners}
          columns={[
            {
              label: t('name'),
              slug: 'name'
            },
            {
              label: t('website'),
              slug: 'website'
            },
            {
              label: t('logo'),
              slug: 'logo'
            }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {partners.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
