import { useCallback, useState } from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme
} from '@mui/material'
import { StyledChip } from 'app/components/chips/multi-select-chip'
import { Cancel } from '@mui/icons-material'

import { Filter } from 'api/models'
import styled from '@emotion/styled'
import { SetURLSearchParams } from 'react-router-dom'

interface IFiltersBoxProps {
  filter: Filter
  onChange?: () => void
  searchParams: URLSearchParams
  setSearchParams: SetURLSearchParams
}

const ITEM_HEIGHT = 32
const ITEM_PADDING_TOP = 4
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      whiteSpace: 'nowrap'
    }
  }
}

const OutlinedInputStyled = styled(OutlinedInput)`
  > div {
    /*padding: 14px;*/
  }
`

const InputLabelStyled = styled(InputLabel)`
  /*top: -8px;
                                        &.Mui-focused {
                                          top: 0;
                                        }*/
`

export function MultipleSelect({
  filter,
  onChange,
  setSearchParams,
  searchParams
}: IFiltersBoxProps) {
  const setDefaultValues = (): string[] => {
    let defaultLabels: string[] = []

    const value = searchParams.get(filter.reference)
    if (value != null) {
      let values: string | undefined
      try {
        values = JSON.parse(value)
      } catch {
        values = value
      }
      if (!values || !Array.isArray(values)) return []
      values.forEach((existingVal: string) => {
        const matchingValue = filter.values.find((value) => value.id == existingVal)
        if (matchingValue) {
          defaultLabels.push(matchingValue.label)
        }
      })
      return defaultLabels
    }

    filter.default.forEach((defaultValue) => {
      const matchingValue = filter.values.find((value) => value.id == defaultValue)
      if (matchingValue) {
        defaultLabels.push(matchingValue.label)
      }
    })
    return defaultLabels
  }
  const theme = useTheme()
  const [selected, setSelected] = useState<string[]>(setDefaultValues())

  const updateSearchParams = useCallback(
    (newSelected: string[]) => {
      setSelected(newSelected)

      const ids = filter.values
        .filter((v) => newSelected.includes(v.label))
        .map((value) => value.id)

      setSearchParams((searchParams) => {
        if (ids.length > 0) {
          searchParams.set(filter.reference, JSON.stringify(ids))
        } else {
          searchParams.delete(filter.reference)
        }
        return searchParams
      })
    },
    [setSelected, filter, setSearchParams]
  )

  const handleChange = useCallback(
    (event: SelectChangeEvent<typeof selected>) => {
      const {
        target: { value }
      } = event

      updateSearchParams(typeof value === 'string' ? value.split(',') : value)

      if (onChange) {
        onChange()
      }
    },
    [onChange, filter, onChange, searchParams, setSearchParams]
  )

  const handleDelete = useCallback(
    (valueToDelete: string) => () => {
      const newSelected = selected.filter((value) => value !== valueToDelete)
      updateSearchParams(newSelected)
      if (onChange) {
        onChange()
      }
    },
    [filter, onChange, searchParams, setSelected, selected, setSearchParams]
  )

  const getStyles = useCallback((name: string, currentValue: readonly string[], theme: Theme) => {
    return {
      whiteSpace: 'normal',
      fontWeight:
        currentValue.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    }
  }, [])

  return (
    <FormControl sx={{ width: 200, height: '100%' }} size="small">
      <InputLabelStyled>{filter.label}</InputLabelStyled>
      <Select
        name={filter.reference}
        multiple
        value={selected}
        onChange={handleChange}
        input={<OutlinedInputStyled label={filter.label} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <StyledChip
                label={value}
                key={value}
                clickable
                onDelete={handleDelete(value)}
                deleteIcon={<Cancel onMouseDown={(e) => e.stopPropagation()} />}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        size="small"
        data-cy={`filter-${filter.reference}`}
      >
        {filter.values.map((value) => (
          <MenuItem
            key={value.id}
            value={value.label}
            style={getStyles(value.label, selected, theme)}
          >
            {value.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
