import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { Key, Mail, Visibility, VisibilityOff } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { useApp } from 'app/providers/app.provider'
import { SignInInputs, signInInputsSchema } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'
import { useState } from 'react'
import { authRouter } from 'api/routes'

export default function SignInForm() {
  const { t } = useTranslation()
  const { setUser } = useApp()
  const { toast } = useFeedback()
  const navigate = useNavigate()

  const methods = useForm<SignInInputs>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: zodResolver(signInInputsSchema)
  })

  const onSubmit = async (data: SignInInputs) => {
    try {
      const user = await authRouter.signIn(data)

      if (user) {
        setUser(user)

        toast({
          message: "You've successfully signed in!",
          variant: 'success'
        })

        return navigate('/')
      }
    } catch (e) {
      toast({
        message: t('sign_in_error'),
        variant: 'error'
      })
    }
  }

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((prev) => !prev)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) =>
    event.preventDefault()

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TextField
          id="email"
          data-cy="sign-in-login"
          label={t('email')}
          variant="outlined"
          required
          fullWidth
          margin="normal"
          error={!!methods.formState.errors.email}
          helperText={
            methods.formState.errors.email
              ? t(`errors.${methods.formState.errors.email?.type}`)
              : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Mail />
              </InputAdornment>
            )
          }}
          {...methods.register('email')}
        />
        <TextField
          id="password"
          data-cy="sign-in-password"
          label={t('password')}
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          required
          fullWidth
          margin="normal"
          error={!!methods.formState.errors.password}
          helperText={
            methods.formState.errors.password
              ? t(`errors.${methods.formState.errors.password?.type}`)
              : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Key />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
          {...methods.register('password')}
        />
        <Button
          data-cy="sign-in-button"
          variant="contained"
          fullWidth
          type="submit"
          disabled={methods.formState.isSubmitting || !methods.formState.isValid}
        >
          {t('sign_in')}
        </Button>
      </form>
    </FormProvider>
  )
}
